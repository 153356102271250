.skill-box {
  margin: 0 0 8em 0;
}

.skill-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 25px;
  margin-bottom: 20px;
  color: #fff;
  padding: 2% 5% 0 5%;
}

.skill-details {
  background-color: #fff;
  color: #fff;
  padding: 5px;
  border-radius: 20px;
  text-align: center;
  width: 100px;
  transition: transform 0.3s, box-shadow 0.3s;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.skill-details:hover {
  transform: translateY(-10px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
}

.skill-img {
  width: 50px;
  height: 50px;
  margin-bottom: 15px;
  border-radius: 50%;
  padding: 5px;
}

.skill-name {
  font-size: 1rem;
  font-weight: 500;
  color: #000;
}

@media (max-width: 500px) {

  .skill-name {
    font-size: 12.5px;
  }

  .skill-container {
    gap: 15px;
    padding: 2% 1% 0 1%;
  }

  .skill-details {
    
    padding: 0;
   
  }
}
