@import url('https://fonts.googleapis.com/css2?family=Baskervville+SC&family=Inconsolata:wght@200..900&family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Mulish:ital,wght@0,200..1000;1,200..1000&display=swap');

:root {
  color-scheme: light;
  --accent-check: #fca311;
  --dark-color: #000;
  --light-color: #fff;
}


html, body{
  min-height: 100vh;
  background-color: #000;
  scroll-behavior: smooth;
  font-family: "Inconsolata", monospace;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  max-width: 100%;
}


::-webkit-scrollbar-thumb {
  opacity: 0;
}

.div-heading {
  color: #fff !important;
  letter-spacing: 20px;
  text-align: center;
  align-items: center;
  margin: 0 0 1em 1em;
  font-weight: normal;
  font-size: 30px;
}

.btn-primary {
  border-radius: 5px;
  color: black;
  margin-right: 15px !important;
  letter-spacing: 1px;
  text-align: center;
  align-items: center;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show>.btn-primary.dropdown-toggle {
  background-color: white !important;
}

.btn-primary:hover {
  background-color: var(--dark-color) !important;
  color: var(--accent-check) !important;
  box-shadow: none;
  border: none;
  transition: color  0.8s ease;
  transition: background-color 0.8s ease;
}

.btn-primary:focus {
  outline: none !important;
  border: none !important;
}

@media (max-width: 500px) {
  .div-heading {
    font-size: 28px;
  }
}

@media (max-width: 380px) {
  .div-heading {
    font-size: 24px;
    margin-left: 1em;
    margin-right: 1em;
  }
}