.navbar{
  background-color: var(--accent-check) !important;
  float: right;
  position: fixed;
  width: 100%;
  z-index: 1000;
  padding: 5px;
}

.navbar-collapse{
  justify-content: right !important;
}

.brandname{
  color: var(--accent-check);
  font-weight: bolder;
}

.nav-link{
  margin: 0 10px 0 10px;
  color: var(--dark-color) !important;
  font-weight: 500;
  letter-spacing: 2px;
  transition: color 0.3s ease;
}

.nav-link.active {
  color: var(--light-color) !important;
}

.nav-link:hover{
  color: var(--light-color) !important;
  transition: color 0.3s ease;
}

.resume-button{
  background-color: var(--light-color) !important;
  border-radius: 5px;
  border: none;
  margin:  0 10px 0 10px;
  padding: 2px 20px;
  color: var(--dark-color) !important;
  letter-spacing: 1px;
  transition: color 0.8s ease;
  transition: background-color 0.8s ease;
}

.btn-primary:hover{
  box-shadow: none !important;
}

.navbar-toggler{
  border: 0 !important;
}

@media (max-width: 992px) {
  .navbar{
    background-color: var(--accent-check) !important;
    padding: 2px;
  }
  .navbar-collapse{
    justify-content: left !important;
    transition: height 0.3s ease-in-out !important;
    will-change: height;
  }

  .nav-link{
    margin: 10px 5px;
    color: var(--dark-color) !important;
    font-weight: bolder;
    letter-spacing: 2px;
    font-size: 14px;
    border-bottom: 1px solid var(--light-color);
    width: 100vh;
  }

  .resume-button{
    background-color: var(--light-color) !important;
    border: none !important;
    outline: none !important;
    margin:  5px 5px;
    padding: 2px 8px;
  }
  
}