.main {
  min-height: 100vh;
  background-color: #000;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  align-content: center;
  padding: 50px;
}

.icon-contact-check {
  align-items: center;
  align-content: center;
  color: #000 !important;
}

.socail-icon-contact-check {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 30px;
  gap: 20px;
  align-items: center;
  margin-top: 0.5em;
}

.hi-check {
  color: var(--light-color) !important;
  margin: 0;
}

.name {
  letter-spacing: 10px;
  font-size: 36px;
  height: 36px;
  margin-bottom: 10px;
  font-weight: 500;
  color: var(--accent-check);
  text-align: left;
  animation: shring-animation 8s ease-in-out 1;

  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  max-width: 0;
  animation: typing 5s steps(2500, end) forwards;
}

@keyframes typing {
  from {
    max-width: 0;
  }
  to {
    max-width: 100%;
  }
}

.container {
  width: 80%;
}

.pro-container {
  border-radius: 20px;
  background-color: var(--light-color);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 5% 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.intro-para a {
  color: var(--dark-color);
  text-decoration: underline;
  font-weight: 400;
}

.intro-para {
  text-align: center;
  padding: 0 !important;
  margin: 0 !important;
  font-weight: 500;
  /* text-shadow:  0px 2px 5px rgba(0,0,0,0.2); */
}

.intro {
  text-align: left;
  color: white !important;
}

.profileImg {
  width: 180px;
  height: auto;
  border-radius: 100%;
  object-fit: cover;
  margin-bottom: 1.5em;
  outline: 3px solid white;
  outline-offset: -10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.scroll-down-div {
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 1px;
  left: 50%;
  transform: translate(-50%);
}

.scroll-down-container {
  height: 45px;
  width: 45px;
  margin-top: 0;
  border-radius: 60px;
  text-align: center;
  animation: scrollDwon 1.5s infinite;
}

.scroll-down-action {
  width: 25px;
  height: 25px;
  border-left: 5px dashed var(--accent-check);
  border-bottom: 5px dashed var(--accent-check);
  transform: rotate(-45deg) translate(50%);
}

@keyframes scrollDwon {
  0% {
    transform: translate(0);
  }

  20% {
    transform: translateY(15px);
  }

  40% {
    transform: translate(0);
  }
}

/* Responsive Design */
@media screen and (max-width: 768px) {

  .main {
    min-height: 100vh;
    background-color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px 20px 50px 20px;
  }
  


  .container {
    width: 100%;
  }


  .name {
    letter-spacing: 5px;
    font-size: 24px;
  }

  .profileImg {
    width: 150px;
  }

  .socail-icon-contact-check {
    font-size: 24px;
    gap: 15px;
  }

  .pro-container {
    padding: 8% 5%;
  }
}

@media screen and (max-width: 480px) {

  .scroll-down-div{
    display: none;
  }

  .main {
    min-height: 100vh;
    background-color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 20px 50px 20px;
  }

  .name {
    letter-spacing: 8px;
    font-size: 26px;
    text-align: center;
    animation: typing 2.5s steps(1000, end) forwards;
  }

  .intro {
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    color: white !important;
  }

  .intro-para {
    font-size: 12.5px;
    line-height: 1.4;
    font-weight: 500;
    text-align: center;
  }

  .profileImg {
    width: 120px;
    outline: 2px solid white;
    outline-offset: -5px;
  }

  .socail-icon-contact-check {
    font-size: 20px;
    gap: 10px;
  }

  .pro-container {
    padding: 3% 5%;
  }


  @media screen and (max-width: 390px) {

    .main {
      padding: 60px 20px 50px 20px;
    }

    .pro-container {
      padding: 3% 5%;
    }

    .intro-para {
      font-size: 12px;
    }
  }
}