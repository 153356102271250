.timeline-box {
    margin-top: 8em;
    width: 100%;
    overflow: hidden;
}

.timeline-container {
    overflow-x: scroll;
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
    scroll-behavior: smooth;
}

.timeline {
    display: flex;
    flex-direction: row;
    overflow-x: hidden;
    scroll-behavior: smooth;
    width: 100%;
}

.continue-line {
    width: 15em;
    margin-top: 25px;
    border: 2px solid white;
    height: 1px;
}

.timeline-content {
    width: 100%; 
    max-width: 100vw;
    display: flex;
    flex-direction: column;
    transition: transform 0.3s, box-shadow 0.3s;
    overflow-x: auto;
    scrollbar-width: thin;
    scrollbar-color: var(--scroll-color) #ffffff;
    scroll-behavior: smooth;
    white-space: nowrap;
    padding-bottom: 20px;
}

.timeline-text {
    margin-top: 1.5em;
    display: flex;
    min-width: 0;
}

.timeline-content::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

.timeline-content::-webkit-scrollbar-track {
    background: #ffffff;
}

.timeline-content::-webkit-scrollbar-thumb {
    background-color: var(--scroll-color);
    border-radius: 3px;
}

.timeline-content::-webkit-scrollbar-thumb:hover {
    background-color: #888;
}

.timeline-event {
    height: 12px;
    width: 12px;
    border: 2px solid white;
    border-radius: 100%;
    background-color: white;
    
}

.next-line {
    margin-top: 25px;
    width: 15em;
    border: 2px solid white;
    height: 1px;
}

.event-description {
    display: flex;
    flex-direction: column;
    text-align: center;
}

.time-description-container {
    padding: 0;
    background-color: var(--light-color);
    border-bottom: 10px solid var(--accent-check);
    border-radius: 20px;
    margin: 0 3em 0 3em;
}


.time-description-container p {
    margin-bottom: 0.5em;
}

.timeline-extra-text {
    font-size: 12px;
    letter-spacing: 0;
    font-weight: 300;
    color: #6c757d;
}

.title-description {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.under-line {
    display: flex;
    flex-direction: row;
}

.timeline-buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 5em 0 10em 0;
}

.timeline-connection {
    border-left: 3px solid var(--light-color);
    height: 20px;
    margin-left: 5px;
}

.event-icon {
    color: var(--dark-color) !important;
    font-size: 26px !important;
    margin-bottom: 2%;
}

.event-date {
    font-size: 18px;
    letter-spacing: 1px;
    color: var(--accent-check);
}


.instruction-text {
    margin-top: 1em;
    margin-left: 0.5em;
    height: 20px;
    color: var(--accent-check);
    opacity: 0.8;
}

.nextButton {
    margin-left: 5px;
    animation: goNext 1s infinite;
    text-align: center;
    align-items: center;
}

@keyframes goNext {
    0% {
        transform: translateX(5px);
    }


    100% {
        transform: translateX(0);
    }
}

.time-description {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--light-color);
    padding: 5% !important;
    border-radius: 20px;

  }
  
  .event-icon {
    font-size: 24px;
    margin-bottom: 10px;
    color: var(--scoll-color);
  }
  
  .time-description p {
    margin: 0;
    padding: 0;
    /* color: #333; */
    font-size: 14px;
  }
  
  .timeline-extra-text {
    margin-top: 5px;
    color: #6c757d;
    font-style: italic;
    font-size: 12px !important;
  }

  .timeline-position{
    font-size: 14px !important;
  }
  
  .title-description {
    margin-top: 10px;
  }
  
  .title-description p {
    margin: 0;
    padding: 0;
    color: #666;
    font-size: 12px;
  }
  
  .title-description p:first-child {
    font-weight: bold;
    color: #444;
  }
  

@media (max-width: 500px) {
    .next-line {
        width: 12em;
    }

    .continue-line {
        width: 12em;
    }

    .time-description-container {
        padding: 5px 0 5px 0;
        border-radius: 10px;
        background-color: white;
        margin: 0 1em 0 1em;
    }

    .time-description-container p {
        margin-bottom: 0.1em;
    }

    .timeline-content {
        border-radius: 0;
        margin-left: 0;
        margin-right: 0;
    }


    .time-description {
        padding: 0;
        font-size: 12px;
    }

}