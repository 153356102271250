.App {
  text-align: center;
  width: 100%;
}

.app-back {
  width: 100%;
}

.footer{
  padding: 0.5em;
  color: white;
  text-align: center;
  width: 100%;
  font-size: 16px;
}

.footer p {
  margin: 0;
  padding: 0;
}

.footer a{
  color: var(--accent-check);
}

.fade-in-y {
  opacity: 0;
  position: relative;
  transform: translateY(50px);
  transition: opacity 1.5s ease-out, transform 0.6s ease-out;
}

.fade-in-y.visible {
  opacity: 1;
  transform: translateY(0);
}

.fade-in-x {
  opacity: 0;
  position: relative;
  transform: translatex(200px);
  transition: opacity 1s ease-out, transform 0.6s ease-out;
}

.fade-in-x.visible {
  opacity: 1;
  transform: translateY(0);
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


@media screen and (max-width: 768px) {
  .footer{
    font-size: 12px;
  }
}